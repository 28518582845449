import {Col, Row, Container, Button, Card, OverlayTrigger, Stack, Popover} from 'react-bootstrap'

import ParagraphPanel from "../components/ParagraphPanel";
import './Games.css'

export default function Games() {
    return (
       <div>
            <ParagraphPanel
                titlePanel="Games"
                textPanel=""
                borderVariant="default"
            />
            <Container>
                <div>
                    <h2>Industry Work</h2>
                    <Row>
                    <Card style={{ width: '18rem' }}>
                        <Card.Body>
                        <Card.Title>ARPG with IP</Card.Title>
                        <Card.Text>
                         <b>Company:</b> Timbre Games
                         <br/>
                         <b>Year:</b> 2023-2024
                         <br/>
                         <b>Genre:</b> ARPG with Roguelike elements
                         <br/>
                         <b>Platform:</b> PC/Switch/PS5/XBOX
                         <br/>
                         <b>Status:</b> Cancelled   
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    {/* <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={
                        <Popover style={{maxWidth: '30rem'}}>
                            <Popover.Body>
                                <Stack direction="horizontal">
                                    <img style={{maxWidth: '8rem'}} src="../images/rfa/IMG_0573.PNG"/>
                                    <img style={{maxWidth: '8rem'}} src="../images/rfa/IMG_0414.PNG"/>
                                    <img style={{maxWidth: '8rem'}} src="../images/rfa/IMG_0577.PNG"/>
                                </Stack>
                            </Popover.Body>
                        </Popover>
                    }> */}
                        <Card style={{ width: '18rem' }}>
                            <Card.Body>
                            <Card.Title>Roll for Adventure</Card.Title>
                            <Card.Text>
                            <b>Company:</b> Truly Social Games
                            <br/>
                            <b>Year:</b> 2020-2021
                            <br/>
                            <b>Genre:</b> IdleKit RPG
                            <br/>
                            <b>Platform:</b> IOS/Android   
                            <br/>
                            <b>Status:</b> Unreleased/Cancelled   
                            </Card.Text>
                            </Card.Body>
                        </Card>
                    {/* </OverlayTrigger > */}
                    </Row>
                </div>
                <h2>Personal Work</h2>
                <Row>
                    <Col xs={12} md={5}>
                        <img className='gameImage' src="../images/prototype/full_prototype.gif" />
                        <br />
                        <br /> <br />
                    </Col>
                    <Col xs={12} md={5}>                
                        <p className='gameParagraph'>Work in progress project to create a quick-paced anomaly spotting game. This was also a quick exercise to learn Unity's UI Toolkit, <b>Unity 6</b> and using Animation Timeline Assets. Currently refactoring/migrating to Godot. </p>
                        <Button variant="dark" size="lg" href="https://ihmayor.itch.io/prototype-mailroom-madness" >Try out the latest iteration!</Button>
                    </Col>
                </Row>



                <Row>
                    <Col xs={12} md={5}>
                        <img className='gameImage' src="../images/chapellogo.png" />
                        <br />
                        <br /> <br />
                    </Col>
                    <Col xs={12} md={5}>                
                        <p className='gameParagraph'>Journey to the Chapel is a type and shoot game made in the <b>Godot Engine</b>. It was made to teach myself elements of the Godot Engine and mapping similarities to my Unity knoweldge. Theme was inspired by a specific VMA 2024 performance</p>
                        <Button variant="dark" size="lg" href="https://ihmayor.itch.io/journey-to-the-chapel" >Play it on Itch.IO!</Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={5}>
                        <img className='gameImage' src="../images/prototype/roachproto.gif" />
                        <br />
                        <br /> <br />
                    </Col>
                    <Col xs={12} md={5}>                
                        <p className='gameParagraph'>EncROACH: Rough prototype exploring couch co-op chained together mechanics alongside stealth mechanics </p>
                        <Button variant="dark" size="lg" href="https://ihmayor.itch.io/encroach" >Try out the prototype!</Button>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={5}>
                        <img className='gameImage' src="../images/prototype/cinemachinetest.gif" />
                        <br />
                        <br /> <br />
                    </Col>
                    <Col xs={12} md={5}>                
                        <p className='gameParagraph'>Pobby spot-em: quick prototype exploring spot-the-difference mechanics with dynamically changing multi-camera perspectives</p>
                        <Button variant="dark" size="lg" href="https://ihmayor.itch.io/plobby" >Try out the prototype!</Button>
                    </Col>
                </Row>


                <Row>
                    <Col xs={12} md={5}>
                        <img className='gameImage' src="../images/StickerLogo.png" />
                        <br />
                        <br /> <br />
                    </Col>
                    <Col xs={12} md={5}>                
                        <p className='gameParagraph'>Sticker Shuffle is a scramble-em and deduce-em card game made in the <b>Unity Engine</b>. It was made to challenge myself in iterating a quick-idea prototype with newfound knowledge I had gained in UI/UX design while working in the industry. This prototype had a limit of five days to go from console-app to Unity project to simply explore the concept.</p>
                        <Button variant="dark" size="lg" href="https://ihmayor.itch.io/sticker-shuffle" >Play on Itch.IO!</Button>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={5}>
                        <img className='gameImage' src="../images/catlogo.png" />
                        <br />
                        <br /> <br />
                    </Col>
                    <Col xs={12} md={5}>                
                        <p className='gameParagraph'> Clouds Awakening Trouble is a 2D third-person <b>block stacking strategy mobile game</b> focused on tower building block by block to reach higher heights.  This was a team project alongside a diverse skillset of artists, designers and managers and developed using the <b>Unity Engine</b>. It was made to <b>demonstrate the team's understanding of engaging game design principles</b>. It is also intended to demonstrate UX/UI principles that originate from games but can be applied more broadly.</p>
                        <Button variant="dark" size="lg" href="../OldWebsite/projectcat.html" >Check out the process!</Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={5}>
                        <img className='gameImage' src="../images/staywarmlogo.png" />
                        <br />
                        <br /> <br />
                    </Col>
                    <Col xs={12} md={5}>                
                        <p className='gameParagraph'> Stay Warm_01 is a <b>2D puzzle desktop platformer game</b> where players must brace the winds to continue pushing forward and seek warmth. This was a side-project to discover and learn the more advanced features of the <b>Unity Engine</b>. The main game loop is <b>Move, Solve, Warm</b>. All animations and graphics were also made by myself. While this project as an exercise has been completed there are plans to continue iterating upon its concept with newfound knowledge of Game Design. </p>
                        <Button variant="dark" size="lg" href="../OldWebsite/projectwarm.html" >Show me more!</Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={5}>
                        <img className='gameImage' src="../images/eelogo.png" />
                        <br />
                        <br /> <br />
                    </Col>
                    <Col xs={12} md={5}>                
                        <p className='gameParagraph'> Endless Embers is a <b>Desktop Java Application 2D Roguelike game</b> made with Slick2D Java Library. Escape from the dark pit your character has fallen into, get past frightning shadow monsters and figure you path out to the surface! The rough game loop is "Move, Uncover, Attack" though this was made more for applying Agile Development Process techniques than game design.</p>
                        <Button variant="dark" size="lg" href="../OldWebsite/projectee.html" >See more!</Button>
                    </Col>
                </Row>
            </Container>
       </div>
    );
}

