import './Home.css'
import ParagraphPanel from '../components/ParagraphPanel';
import MainCarousel from '../components/MainCarousel';
import {Accordion} from 'react-bootstrap';
import React from 'react';

export default function Home(){
  return (
    <div className="App">
      <ParagraphPanel
        titlePanel="Full-Stack Software Developer"
        textPanel="Most Recently: UE5 Build Engineer, .NET Developer, Unity Programmer"
        borderVariant="default"
      />
      <div className="MainContent">
        <img 
          src="images/Profile_Base.jpg" 
          className="ProfilePic"
          alt="Profile Picture"
        />
        <div>
          <h4 className="AboutMeTitle">
            Located in Vancouver. If you have a collaborative team needing a <span className="ColorBold"><b>programmer who's driven, communicative and well-experienced in solving and optimizing complex multi-platform problems to unblock others</b></span> then let's connect!
              <br/><br/>
          </h4>
          <div className="AccordionParagraph">
              <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>My Passion</Accordion.Header>
                  <Accordion.Body>
                    I love <b>creating the technical foundations behind the scenes </b>that provides <b>ease of use for users</b> to facilitate the process of developing or working with the system, whether that be creative projects or otherwise. <i>Technology should adapt to people, not the other way around</i> is one of my driving mottos. 
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>My Work Experience</Accordion.Header>
                  <Accordion.Body>
                    I have been programming for over eight years, six years of work experience, two of which have been in Games. I love adapting to whatever opportunies are presented to me and dive headfirst into a diverse range of technologies. Give me a few weeks, and I'll start figuring things out! I do have four years Unity experience as I have used Unity in non-game software projects.
                    <br/>
                    <br/>
                    Most recently, I worked at <b>Timbre Games Studio</b> where I worked as a <b>Build Engineer</b> working on CI/CD pipelines in <b>TeamCity</b> for a <b>UE5</b> project targeting PC/Consoles such as <b>Nintendo Switch</b>. I would've loved to continue growing there but unfortunately it was shut down by our parent company, Sumo Digital. 
                    Before that, I worked at <b>Powerex Corp (Subsidiary of BCHydro)</b> as a <b>.NET Developer</b> working on <b>.NET Framework</b> solutions, <b>React</b> Web Apps and <b>VBA</b> Add-ins, in an 80+ project migration. My other work has been 
                    in <b>mobile game development</b> targetting both IOS/Android at and in <b>data management/processing optimizations</b> in Java. I still work on personal projects, like designing game mechanics in <b>Godot</b> or <b>Unity</b>, exploring AR projects for mobile devices, or when I need a break from screens, I'm cross-stitching!
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>My Skillsets</Accordion.Header>
                  <Accordion.Body>
                  ● Languages: C#/.NET, Python, Java, JavaScript/HTML/CSS, VBA, C/C++, PHP, SQL/NoSQL
                  <br/>
                  ● Frameworks and Libraries: Nintendo SDK, React.js, Robot Framework, JUNIT, NUNIT, D3, Angular.js, SignalR, Tizen OS, Node.js, Kinect SDK
                  <br/>
                  ● Tools: Git, Perforce, Team Foundation Server, Jenkins, TeamCity, Tableau, Trifecta Wrangler, Hololens, Arduino
                  <br/>
                  ● Game Engines: UE5, Unity, Godot
                  <br/>
                  <br/>
                  <i>
                  Technology is always rapidly changing, so I excel at adapting to the latest language, tool, or framework required. The ones listed are my core/latest acquired skills but as a generalist, it is not exhaustive.
                  </i>
                  </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                  <Accordion.Header>My Education</Accordion.Header>
                  <Accordion.Body>
                    I have a <b>Bachelor's degree in Computer Science</b> with a concentration in Human Computer Interactions (UI/UX) from the University of Calgary.
                    <br/><br/>
                    I have a <b>Master's degree in Digital Media </b> from the Centre of Digital Media (SFU, UBC, BCIT, ECUAD Joint Graduate Program) in Vancouver. This was an intense graduate program that provided me the opportunity and experience working directly with diverse (technical/non-technical) teams such as designers, artists, product owners.
                    <br/><br/>
                    I have a <b>DELF A2 Diploma</b> (Basic French Comprehension Diploma).
                  </Accordion.Body>
                </Accordion.Item>
                </Accordion>
              <br/>
          </div>
        </div>
      </div>
      
      <div className="parallax">
        <h2 className="animate__animated animate__fadeInDown ParallaxHeader">
        Featured Personal Projects
        </h2>
      </div>
      <MainCarousel/>
    </div>
  );
}